import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "CheckedRules",
	model: {},
	props: {
		icon: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "",
		},
		display_text: {
			type: String,
			default: "",
		},
	},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
		...mapGetters("proccess", ["isLoading"]),
	},
	methods: {},
	watch: {},
});

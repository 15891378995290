
// @ts-ignore
import PasswordRules from "@/components/Content/Password/Rules/index.vue";
import {
	isRequired,
	isEmail,
	isMinPassword,
	isValidPassword,
	isMatchingPasswords,
	// @ts-ignore
} from "@/services/rule-services";
import { last } from "lodash";
import { mapActions, mapGetters } from "vuex";
import { isPasswordPassed } from "../../services/rule-services";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default {
	name: "Init",
	props: {},

	data: function () {
		return {
			localLoading: false,
			disabledSubmit: true,
			init_token: undefined,
			email: "",
			password: "",
			password_confirmation: "",
			name: "",
			last_name: "",
			valid: false,
			rules: {
				isRequired,
				isMinPassword,
				isEmail,
				isValidPassword,
				isMatchingPasswords,
			},
			show: {
				password: false,
				password_confirmation: false,
			},
		};
	},

	created() {
		this.$nextTick(async () => {
			document.title = this.$t("init.title");
			this.disabledSubmit = !isPasswordPassed(this.password);
			await this.getLangFromQuery();
		});
	},

	mounted() {
		this.setToken;
	},

	computed: {
		...mapGetters("loading", ["isLoading"]),
		setToken() {
			let pathArray: String[] = this.$route.path.split("/");
			const lastItem = last(pathArray);
			this.init_token = lastItem;
		},
	},
	components: { PasswordRules },
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("auth", ["init"]),
		...mapActions("internationalization", ["setLanguage"]),

		async getLangFromQuery() {
			const { language } = this.$route.query;
			if (!language) return;
			const supportedLanguages = ["es", "en", "pt"];
			if (!language || !supportedLanguages.includes(language)) return;
			this.setLanguage({language});
		},

		async setLoading(load: Boolean = false) {
			this.localLoading = load;
		},
		async validate() {
			let form = this.$refs.form;
			return form.validate();
		},
		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				this.setLoadingData(TypeLoading.loading);
				await this.init({
					email: this.email,
					name: this.name,
					last_name: this.last_name,
					password: this.password,
					password_confirmation: this.password_confirmation,
					init_token: this.init_token,
				});
				await this.setLoadingData();
				this.backToLogin();
			} catch (error) {
				await this.setLoadingData();
			}
		},
	},

	watch: {
		password(val: string) {
			this.disabledSubmit = !isPasswordPassed(val);
		},
	},
};

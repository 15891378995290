import Vue from "vue";
import { mapGetters } from "vuex";
import CheckedRules from "@/components/Content/Password/Checked/index.vue";
import {
	hasDigit,
	hasLength,
	hasLower,
	hasSpecialChar,
	hasUpper,
	getPasswordLength
} from "@/services/rule-services";

export default Vue.extend({
	name: "PasswordRules",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
	},
	components: { CheckedRules },
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
		...mapGetters("proccess", ["isLoading"]),

		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},

		getPasswordLength() {
			return getPasswordLength();
		},

		/**
		 * Validations
		 */
		hasLength() {
			return hasLength(this.modelData);
		},

		hasDigit() {
			return hasDigit(this.modelData);
		},

		hasLower() {
			return hasLower(this.modelData);
		},

		hasUpper() {
			return hasUpper(this.modelData);
		},

		hasSpecialChar() {
			return hasSpecialChar(this.modelData);
		},
	},
	methods: {},
	watch: {},
});
